import React, { useMemo } from "react";
import { View } from "react-native";
import cursor from "src/constants/cursor";
import useDim from "src/hooks/useDim";
import useStyle from "src/hooks/useStyle";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import { paddingLarge, paddingMedium, paddingSmall, paddingXXXL } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import Loading from "../Loading";
import HollowBackground from "./components/HollowBackground";
import PrimaryBackground from "./components/PrimaryBackground";
import TouchableWrapper from "./components/TouchableWrapper";
import { RoundedButtonCircleLargeSize } from "./constants/RoundedButtonCircleLargeConstants";
import useAutoMinDim from "./hooks/useAutoMinDim";
import { RoundedButtonProps } from "./types";

const RoundedButton = ({
	testID,
	onPress,
	style,
	disabled = false,
	loading = false,
	avoidShrink = false,
	containerStyle,
	error = "",
	children,
	roundRadius: roundRadiusProp,
	colorVariant = "Primary",
	sizeVariant = "Medium",
	Icon,
	textProps,
	href,
	target,
}: RoundedButtonProps) => {
	const { theme } = useStyle();
	const dim = useDim();

	const { backgroundColor, textColor, borderWidth, borderColor } = useMemo<{
		backgroundColor?: string;
		textColor?: string;
		borderWidth?: number;
		borderColor?: string;
	}>(() => {
		switch (colorVariant) {
			case "Primary":
				return {
					textColor: color.Text[theme],
				};
			case "Match":
				return {
					backgroundColor: color.ButtonBackground[theme],
					textColor: color.Text[theme],
				};
			case "Contrast":
				return {
					backgroundColor: color.ButtonBackgroundContrast[theme],
					textColor: color.TextMatch[theme],
				};
			case "Confirmation":
				return {
					backgroundColor: color.Confirmation[theme],
					textColor: color.Text[theme],
				};
			case "Destructive":
				return {
					backgroundColor: color.Destructive[theme],
					textColor: color.Text[theme],
				};
			case "Hollow":
				return {
					textColor: color.Text[theme],
				};
			default:
				return enumNever(colorVariant);
		}
	}, [colorVariant, theme]);

	const {
		paddingVertical = 0,
		paddingHorizontal = 0,
		goalMinWidth = 0,
		roundRadius: fallbackRoundRadius,
		maxWidth,
		iconSize = 16,
		iconPadding = paddingMedium,
		width,
		height,
	} = useMemo<{
		paddingVertical?: number;
		paddingHorizontal?: number;
		goalMinWidth?: number;
		roundRadius?: RoundedButtonProps["roundRadius"];
		maxWidth?: number;
		iconSize?: number;
		iconPadding?: number;
		width?: number;
		height?: number;
	}>(() => {
		switch (sizeVariant) {
			case "Small":
				return {
					paddingVertical: paddingLarge,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 128,
					maxWidth: 128,
					roundRadius: 24,
				};
			case "Medium":
				return {
					paddingVertical: paddingLarge,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 164,
					maxWidth: 164,
					roundRadius: "Medium",
				};
			case "Large":
				return {
					paddingVertical: paddingLarge,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 326,
					maxWidth: 326,
					roundRadius: "Medium",
				};
			case "Mini":
				return {
					paddingVertical: paddingMedium,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 84,
					maxWidth: 84,
					roundRadius: "Small",
					iconSize: 12,
				};
			case "SkinnySmall":
				return {
					paddingVertical: paddingSmall,
					paddingHorizontal: paddingMedium,
					goalMinWidth: 132,
					maxWidth: 132,
					roundRadius: "Small",
				};
			case "Skinny":
				return {
					paddingVertical: paddingMedium,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 164,
					maxWidth: 164,
					roundRadius: "Small",
					iconSize: 12,
				};
			case "SkinnyLarge":
				return {
					paddingVertical: paddingSmall,
					paddingHorizontal: paddingXXXL,
					goalMinWidth: 280,
					maxWidth: 280,
					roundRadius: "Small",
				};
			case "Flex":
				return {
					paddingVertical: paddingMedium,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 0,
					roundRadius: "Small",
				};
			case "Auto":
				return {
					paddingVertical: paddingMedium,
					paddingHorizontal: paddingLarge,
					goalMinWidth: 0,
					roundRadius: "Small",
				};
			case "CircleLarge":
				return {
					width: RoundedButtonCircleLargeSize,
					height: RoundedButtonCircleLargeSize,
					roundRadius: RoundedButtonCircleLargeSize / 2,
					iconSize: 24,
				};
			default:
				return enumNever(sizeVariant);
		}
	}, [sizeVariant]);

	const { borderRadius } = useMemo<{
		borderRadius: number;
	}>(() => {
		const roundRadius = roundRadiusProp ?? fallbackRoundRadius;
		if (roundRadius === "Small")
			return {
				borderRadius: 20,
			};
		if (roundRadius === "Medium")
			return {
				borderRadius: 26,
			};
		return {
			borderRadius: roundRadius ?? 20,
		};
	}, [fallbackRoundRadius, roundRadiusProp]);

	// Width handling
	const { autoMinWidth, autoMinHeight, handleLayoutChange } = useAutoMinDim(avoidShrink);
	const flex = sizeVariant === "Flex";
	const minWidth = Math.max(goalMinWidth, autoMinWidth);
	const minHeight = Math.max(40, autoMinHeight);

	const finalPaddingHorizontal = Math.max(0, paddingHorizontal - (borderWidth ?? 0));
	const finalPaddingVertical = Math.max(0, paddingVertical - (borderWidth ?? 0));

	return (
		<View
			testID={testID}
			style={[
				containerStyle,
				{
					flexGrow: +flex,
				},
			]}
		>
			<TouchableWrapper
				onLayout={handleLayoutChange}
				onPress={onPress}
				disabled={disabled}
				loading={loading}
				href={href}
				target={target}
				style={[
					{
						backgroundColor,
						borderRadius,
						minHeight,
						alignSelf: flex ? undefined : "center",
						borderWidth,
						borderColor,
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						paddingHorizontal: finalPaddingHorizontal,
						paddingVertical: finalPaddingVertical,
					},
					style,
					{
						minWidth: Math.min(minWidth, dim.width - 2 * paddingLarge),
						width,
						height,
					},
				]}
				containerStyle={{
					cursor: loading || disabled ? cursor.notAllowed : cursor.pointer,
					// Doesn't update properly when in style for some reason
					opacity: disabled ? 0.5 : 1,
					maxWidth:
						maxWidth != null
							? Math.min(maxWidth, dim.width - 2 * paddingLarge)
							: undefined,
				}}
			>
				{colorVariant === "Primary" && <PrimaryBackground borderRadius={borderRadius} />}
				{colorVariant === "Hollow" && <HollowBackground borderRadius={borderRadius} />}
				{(() => {
					if (loading) {
						return <Loading color={textColor} />;
					}
					if (error) {
						return (
							<Text semibold small color="Error">
								{error}
							</Text>
						);
					}
					return (
						<>
							{Icon && (
								<Icon
									style={{
										marginRight: children ? iconPadding : 0,
									}}
									size={iconSize}
									color={textColor}
								/>
							)}
							{(() => {
								if (!children) return null;
								if (typeof children === "string") {
									return textProps ? (
										<Text
											{...textProps}
											style={[
												{
													color: textColor,
												},
												textProps?.style,
											]}
										>
											{children}
										</Text>
									) : (
										<Text
											semibold
											small
											style={{
												color: textColor,
											}}
										>
											{children}
										</Text>
									);
								}
								return children;
							})()}
						</>
					);
				})()}
			</TouchableWrapper>
		</View>
	);
};
export default RoundedButton;
